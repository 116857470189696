<template>
  <div id="app">
    <redoc-wrapper v-if="url" :spec-or-spec-url="url" :options="redocOptions"></redoc-wrapper>
  </div>
</template>

<script>
import axios from 'axios'
import RedocWrapper from '@hnluu8/vue-redoc-wrapper'
import { get_domain_url, get_base_url, downloadWithAxios } from '@/core/services/fileDownload';
  
export default {
  name: 'app',
  components: {
    RedocWrapper
  },
  mounted() {
    axios 
      .get('/page/api_doc_token')
      .then(res => {

        let url = get_base_url();

        if (url.indexOf('localhost') >= 0) {
          this.url = `http://localhost:3020/swagger-doc-${res.data.api_doc_token}`;
        }
        else {
          this.url = `${url}/swagger-doc-${res.data.api_doc_token}`;
        }
        
      })
      .catch(err => {
        console.error(err);
      });
  },
  data() {
    return {
      url: null,
      // https://github.com/Redocly/redoc#redoc-options-object
      redocOptions: {
        hideDownloadButton: false      
      }
    }
  }
}
</script>